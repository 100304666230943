import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { ImageInterface, SanityCtaBlock, VideoInterface } from "../../types/SanityTypes";
import CtaBlock from "../CtaBlock";
import VideoBlock from "../VideoBlock";
import Section from "../Section";
import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

interface SimpleBannerInterface {
  _id: string;
  heading?: string;
  subHeading?: string;
  heroLabel?: string;
  contentAlignment?: string;
  isVideoBanner?: boolean;
  simpleBannerImage?: ImageInterface;
  _rawSimpleBannerImage?: ImageInterface;
  _rawSimpleBannerVideo?: VideoInterface;
  ctaLink?: SanityCtaBlock;
  primaryColor?: {
    title: string;
    value: string;
  };
}

const SimpleBanner: FunctionComponent<SimpleBannerInterface> = props => {
  const {
    heroLabel,
    heading,
    subHeading,
    isVideoBanner,
    simpleBannerImage,
    _rawSimpleBannerVideo,
    ctaLink,
    contentAlignment,
    primaryColor
  } = props;

  const sanityImage = simpleBannerImage;
  const video = _rawSimpleBannerVideo;
  return (
    <Section sectionClassName={`simple-banner ${props._id}`}>
      <div className="simple-banner__wrapper">
        <div className={`simple-banner__bg overlay--${primaryColor?.title}`}>
          {video && isVideoBanner ? (
            <VideoBlock
              classnames="simple-banner__bg-video"
              webm={video.webm}
              fallback={video.fallback}
              alt={video.alt}
              autoPlay
              muted
              loop
            />
          ) : (
            !isVideoBanner &&
            sanityImage && (
              <SanityImage
                sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
                {...sanityImage}
                className="simple-banner__bg-img"
                width={644}
                height={453}
                htmlWidth={644}
                htmlHeight={453}
              />
            )
          )}
        </div>
        <div className={`simple-banner__content alignment--${contentAlignment}`}>
          {heroLabel && <div className="simple-banner__hero-label">{heroLabel}</div>}
          <div className="simple-banner__heading-block">
            {heading && <div className="simple-banner__heading">{heading}</div>}
            {subHeading && <div className="simple-banner__subheading">{subHeading}</div>}
          </div>
          {ctaLink && ctaLink.ctaLabel && (
            <CtaBlock
              ctaBlock={ctaLink}
              ctaLabel={ctaLink.ctaLabel}
              linkClassName="c-link__button simple-banner__cta"
            />
          )}
        </div>
      </div>
    </Section>
  );
};

export default SimpleBanner;
